.kbd {
  outline: 1px solid rgba(22,22,22,0.7);
  margin: 0.1rem;
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;;
  background: #EDF2F7;
  border-radius: 0.375rem;
  border-width: 1px 1px 3px;
  font-size: 1em;
  font-weight: 700;
  line-height: normal;
  padding-inline: .8em;
  white-space: nowrap;
  color: black;
}
