
/**
 * ==============================================
 * Dot Falling
 * ==============================================
 */
.stage {
  display: inline-flex;
  min-width: 50px;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1rem 0;
  align-self: end;
  overflow: hidden;

  .dot-falling {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #1DB8E0;
    color: #1DB8E0;
    box-shadow: 9999px 0 0 0 #1DB8E0;
    animation: dotFalling 1s infinite linear;
    animation-delay: .1s;
  }
}



.dot-falling::before, .dot-falling::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-falling::before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #1DB8E0;
  color: #1DB8E0;
  animation: dotFallingBefore 1s infinite linear;
  animation-delay: 0s;
}

.dot-falling::after {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #1DB8E0;
  color: #1DB8E0;
  animation: dotFallingAfter 1s infinite linear;
  animation-delay: .2s;
}

@keyframes dotFalling {
  0% {
    box-shadow: 9999px -10px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9999px 0 0 0 #1DB8E0;
  }
  100% {
    box-shadow: 9999px 10px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dotFallingBefore {
  0% {
    box-shadow: 9985px -10px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9985px 0 0 0 #1DB8E0;
  }
  100% {
    box-shadow: 9985px 10px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dotFallingAfter {
  0% {
    box-shadow: 10004px -10px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 10004px 0 0 0 #1DB8E0;
  }
  100% {
    box-shadow: 10004px 10px 0 0 rgba(152, 128, 255, 0);
  }
}
