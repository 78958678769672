#app {
	min-width: 840px;
}


// Remove this
.removeAbs > * {
	position: static !important;
	padding: 0;
}


.infoMedia-par {
	word-wrap: anywhere;
	margin: 0.5rem;
	b {
		font-weight: bolder;
	}
}

.queue-data {
	.queue-info {
		b {
			font-weight: bolder;
			color: #4d6782;
		}
		span {
			color: rgb(29, 184, 224);
		}
	}
}

/*
header {
	width: 100%;
	margin: 0 auto;
	padding: 10px;
	position: fixed;
	z-index: 99999;
	background: white;
	top: 0;
	.header-content {
		max-width: 1280px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
	}
}
*/
.no-results {
	text-align: center;
	height: 100%;
	background-color: white;
	margin-top: 100px;

	p {
		padding-top: 150px;
	}
}

.queue-info {
	display: flex;
	align-items: center;

	.icon {
		height: 24px;
		margin-right: 15px;
		cursor: default;

		&.in-edit {
			cursor: pointer;
		}
	}
	.name {
		color: white;
		margin-right: 10px;
	}
	.size {
		margin-right: auto;
		color: #71849d;
	}
}

.canvas-stage {
	position: absolute;
	z-index: 9999;
}

.queue-actions {
	display: flex;
	align-items: center;

	.limit-selector {
		color: #545f72;
		height: 22px;
		background: white;
		border: none;
		border-radius: 5px;
		padding: 0 5px;
		cursor: pointer;
		outline: none;
	}

	.size-selector {
		border-radius: 5px;
		margin-left: 20px;
		display: flex;
		overflow: hidden;
		background: white;

		button {
			color: #545f72;
			height: 22px;
			box-sizing: border-box;
			background: white;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 28px;
			border: none;
			cursor: pointer;

			&.active {
				background-color: #9aa8b8;
				color: white;
				border-radius: 2px;
				pointer-events: none;
			}
		}
	}

	.load-more {
		color: white;
		letter-spacing: 0;
		background-color: #3bb7de;
		border: none;
		border-radius: 5px;
		padding: 9px 19px 9px 14px;
		font-size: 12px;
		font-weight: 500;
		position: relative;
		display: flex;
		align-items: center;
		margin-left: 30px;
		cursor: pointer;

		&:after {
			content: ">";
			position: absolute;
			font-size: 10px;
			right: 9px;
			font-weight: 500;
		}
	}
}

.mode-selector {
	display: flex;

	label {
		margin-bottom: 0;
		margin-right: 5px;
		font-weight: 400;
		width: 40px;
		text-align: center;
		letter-spacing: 0.4px;
		display: flex;

		&[for="mode-logo"] {
			color: #c85142;
		}

		&[for="mode-object"] {
			color: #f1be48;
		}
	}

	input {
		margin-bottom: 0;
		margin-right: 3px;
		font-weight: 400;
		width: 40px;
		text-align: center;
		letter-spacing: 0.4px;
		display: none;

		&[for="mode-logo"] {
			color: #c85142;
		}

		&[for="mode-object"] {
			color: #f1be48;
		}

		+ span {
			cursor: pointer;
		}

		&:checked + span {
			font-weight: bold;
			letter-spacing: 0px;
			pointer-events: none;
		}

		&:disabled + span {
			pointer-events: none;
			opacity: 0.5;
		}
	}
}

.actions-bar {
	width: 100%;
	background-color: #182a4d;
	top: 50px;
	padding: 5px;

	.actions {
		max-width: 1280px;
		height: inherit;
		margin: 0 auto;
		padding: 0 10px;
		color: white;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.queue-size {
		height: inherit;
		color: #71849d;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.brand-preview {
	margin-bottom: 10px;
	width: 100%;

	.selected-preview {
		background-image: url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAAUABQDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9pK838ff8jZd/8A/9AWj/AIT3Vv8An7/8hJ/hXSaBoFp4n0mK+vovPup8733Fd2CVHAIHQDtQBwdFekf8IDpP/Pp/5Ff/ABooA83r0jwD/wAinaf8D/8AQ2oooA2KKKKAP//Z");
		width: 100%;
		height: 225px;
		margin-bottom: 10px;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}

	.previews {
		display: flex;
		flex-wrap: wrap;
	}

	.preview-container {
		width: 64px;
		height: 64px;
		border: 1px solid #d8d8d8;
		border-radius: 5px;
		float: left;
		margin-right: 8px;
		margin-bottom: 8px;
		background-color: #d8d8d8;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 3px;

		&.selected {
			border: 1px solid #000;
			pointer-events: none;
		}

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
}

.class-selector {
	.class-selector-input {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 40px;
		background: white;

		> div {
			padding-bottom: 0 !important;
			padding: 0;
		}
	}
}

.card {
	&.selected {
		outline: 5px solid lightcoral;
	}
}

.visuaLabel {
	margin-bottom: 0;
	font-size: 12px;
	color: #4d6782;
}

.detections-card {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	margin: 5px;
	z-index: 9;
	height: 220px;
	overflow: hidden;
	background: #dcdcdc;

	> img {
		width: 100%;
	}

	&:last-child {
		margin-right: auto;
	}

	&:hover {
		.overlay {
			background-color: #010009;
			opacity: 0.5;
			outline: none;
		}

		.overlay-button {
			opacity: 1;
		}
	}

	&.error {
		cursor: not-allowed;
		> img,
		.overlay,
		.overlay-button {
			display: none;
		}
	}

	&.submitted {
		pointer-events: none;
		cursor: default;

		> img:not(.media-submitted) {
			filter: brightness(0.25);
		}
	}

	.media-error {
		width: 50%;
		text-align: center;
		padding: 20px 0px;
		line-height: 1.25;
	}

	.overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 99;
		opacity: 0;
		transition: opacity 0.1s;
	}

	.overlay-button {
		position: absolute;
		z-index: 999;
		margin: 0;
		font-size: 18px;
		transition: opacity 0.1s;
		width: 23px;
		height: 23px;
		background: #3bb7de;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 0;
		border: 6px solid #3bb7de;
	}

	.media-submitted {
		position: absolute;
		z-index: 99999;
		width: 24px;
		height: 24px;
		right: 10px;
		bottom: 10px;
	}
}

.action {
	font-size: 30px;
	font-size: 12px;
	font-weight: 500;
	border-radius: 5px;
	padding: 9px 14px;
	display: flex;
	align-items: center;
	cursor: pointer;

	&.close-action {
		color: #e5503f;
		border: 1px solid #e5503f;
	}

	&.save-action {
		background-color: #3bb7de;
		color: white;
		margin-left: 20px;
	}

	img {
		margin-left: 10px;
	}
}

.annotation-info {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	margin-right: 20px;
	background: dimgrey;
	border-radius: 10px;
	cursor: pointer;
	z-index: 10;

	&:hover {
		.info {
			display: flex;
		}
	}

	.info {
		z-index: 9;
		flex-direction: column;
		width: fit-content;
		max-width: 200px;
		padding: 14px 20px;
		border-radius: 4px;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: -100%;
		right: calc(100% + 10px);
		background: #000;
		cursor: default;
		display: none;
		text-align: center;
		width: 100px;
		line-height: 1.25;
		box-sizing: content-box;

		img {
			display: block;
		}

		&:after {
			z-index: 8;
			content: "";
			background: #000;
			width: 10px;
			height: 10px;
			position: absolute;
			transform: rotate(45deg) translate(-71%, 0);
			left: 100%;
			top: 21%;
		}
	}
}

.media-grid {
	max-width: 1280px;
	margin: 0 auto;
	padding: 5px;
	display: flex;
	flex-wrap: wrap;
	position: relative;

	align-items: center;

	&.grid-6 {
		.card {
			width: calc(100% / 6 - 10px);
		}
	}

	&.grid-4 {
		.card {
			width: calc(100% / 4 - 10px);
		}
	}

	&.grid-3 {
		.card {
			width: calc(100% / 3 - 10px);
		}
	}
}

.editor {
	display: flex;
	bottom: 0;
	height: calc(100vh - 128px);
	width: 100%;

	.detections-canvas {
		display: flex;
		position: relative;
		z-index: 99;
		align-items: center;
		justify-content: center;
		width: 80%;
		background: rgba(214, 219, 225, 1);
		// min-height: ${({ minHeight }) => minHeight}px;
		// min-width: ${({ minWidth }) => minWidth}px;
	}

	.canvas-bg {
		position: relative;
		z-index: 999;
	}
}

.toolbar {
	background-color: white;
	width: 24%;
	min-width: 320px;
	height: calc(100vh - 128px);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	border-left: 1px solid #f5f5f5;
	padding: 15px;
	overflow: auto;

	.detection-mode {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		margin-bottom: 20px;
	}

	.detection-delete {
		font-size: 12px;
		height: 28px;
		font-weight: 500;
		border-radius: 5px;
		padding: 9px 14px;
		display: flex;
		cursor: pointer;
		border: 1px solid #9aa8b8;
		background: transparent;
		color: #9aa8b8;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		margin-top: auto;
	}

	.detection-add {
		width: 32px;
		height: 32px;
		border: 10px solid #3bb7de;
		margin-right: 15px;
		background: #3bb7de;
		cursor: pointer;
	}
}

.editor-actions {
	display: flex;
	align-items: center;
}

.annotations {
	display: flex;
	flex-direction: column;
	width: 100%;

	.media-grid {
		width: 100%;
	}

	&.submitted {
		.annotations-card {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			margin: 10px 5px;
			background: #dcdcdc;
			box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
				0 2px 1px -1px rgba(0, 0, 0, 0.12);

			&.has-error {
				box-shadow: 0px 0px 4px 1px #f00;
			}
		}
	}
}

.annotations-card {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 10px 5px;
	background: #dcdcdc;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
	min-width: 380px;

	&.has-error {
		box-shadow: 0px 0px 4px 1px #f00;
	}

	.annotations-previews {
		display: flex;
		width: 100%;
		height: 200px;
		position: relative;
	}

	.side {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50%;

		&.preview-side {
			padding: 15px;
			position: relative;

			.preview-info {
				position: absolute;
				bottom: 100%;
				background: black;
				color: wheat;
			}

			img {
				width: auto;
				max-width: 90%;
				max-height: 90%;
			}
		}

		&.canvas-side {
			overflow: hidden;
			position: relative;

			.canvas-stage-annotations {
				top: 0;
				left: 0;
				position: absolute;
			}

			.canvas-stage-img {
				top: 0;
				left: 0;
				position: absolute;
			}
		}
	}

	&:last-child {
		margin-right: auto;
	}

	.ynp-buttons {
		display: flex;
		width: 100%;
		border-top: 1px solid #d9d9d9;
		border-bottom: 1px solid #d9d9d9;

		button {
			width: calc(100% / 3);
			display: flex;
			align-items: center;
			justify-content: center;
			height: 40px;
			border: none;
			background: white;
			font-weight: 700;
			color: #666;

			&:not(.active) {
				cursor: pointer;
			}

			&:hover,
			&.active {
				color: white;
			}
		}

		.ynp-y {
			border-left: 1px solid #d9d9d9;

			&:hover {
				background-color: #5cb85c;
				border-left: 1px solid #5cb85c;
			}

			&.active {
				background-color: #449d44;
				border-left: 1px solid #449d44;
			}
		}

		.ynp-p {
			border-left: 1px solid #d9d9d9;
			border-right: 1px solid #d9d9d9;

			&:hover {
				background-color: #ff8c50;
			}

			&.active {
				background-color: #fb5f0e;
			}
		}

		.ynp-n {
			border-right: 1px solid #d9d9d9;

			&:hover {
				background-color: #d9534f;
				border-right: 1px solid #d9534f;
			}

			&.active {
				background-color: #c9302c;
				border-right: 1px solid #c9302c;
			}
		}
	}
}

.annotations-actions {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 35px;
	padding-bottom: 50px;

	button {
		background-color: #337ab7;
		cursor: pointer;
		padding: 10px 16px;
		width: 40%;
		border: 1px solid #2e6da4;
		border-radius: 6px;
		color: white;

		&:hover {
			background-color: #0f608b;
		}
	}
}

#detections-toggler {
	color: #545f72;
	height: 22px;
	box-sizing: border-box;
	background: white;
	border: none;
	border-radius: 5px;
	padding: 0 5px;
	cursor: pointer;
	margin-right: 18px;

	&:focus {
		outline: none;
	}
}

.labelEdit {
	color: #a1a1a1;
	font-size: 10px;
	font-weight: 500;
}

.span-yes {
	color: #449d44;
}

.span-maybe {
	color: #f1be48;
}

.span-no {
	color: #c85142;
}

#brand-confirm {
	margin-top: auto;
	flex-direction: column;
	width: 100%;

	span {
		margin-bottom: 5px;
		color: #a1a1a1;
		font-size: 10px;
		font-weight: 500;
		display: block;
	}

	div {
		width: 100%;
		display: flex;

		button {
			width: 33.3%;
			background: #0000;
			font-size: 12px;
			height: 28px;
			padding: 0;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #9aa8b8;
			font-weight: 500;
			cursor: pointer;
		}

		button:first-child {
			color: #449d44;
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
			border-right: none;
		}

		button:nth-child(2) {
			color: #f1be48;
		}

		button:last-child {
			color: #c85142;
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
			border-left: none;
		}

		button.approved {
			background-color: #3bb7de;
			color: white;
			cursor: none;
			pointer-events: none;
		}
	}
}

.annotations-info {


		max-width: 200px;
		padding: 3px 8px;
		color: #fff;
		text-align: center;
		background-color: #000;
		border-radius: 4px;
		display: block;
		font-size: 12px;
		line-height: 1.25;

		.canvas-stage-annotations {
			top: 0;
			left: 0;
			position: relative;
		}

		.canvas-stage-img {
			top: 0px;
			left: 0px;
			position: absolute;
		}

}



.annotations-info-tooltip {
	.react-tooltip-lite {
		padding: 10px;
		opacity: 1;
		width: 520px !important;
		height: 520px !important;
		overflow: hidden;
		position: relative;
		display: flex;
	}

	.canvas-img-wrapper {
		width: 500px !important;
		height: 500px !important;
		overflow: hidden;
		position: absolute;
		display: block;
		margin: 0;
	}
}

.ocr-input {
	width: 100%;
	height: 100%;
	border: none;
	text-align: center;
	border: 1px solid #d9d9d9;
	border-radius: 0;
	outline: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	color: #666;
	padding: 10px;
}

.key__button {
	width:35px;
	height: 37px;
	padding: 1px;
	margin: 10px;
	background: #FFF;
	&.special > span:active {
		margin-top: 2px;
		box-shadow: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
	}

	> span {
		display: block;
		width: 35px;
		height: 35px;
		border: 1px solid #a9a9a9;
		border-radius: 2px 2px 2px 2px;
		-moz-border-radius: 2px 2px 2px 2px;
		-webkit-border-radius: 2px 2px 2px 2px;
		font-size: 12px;
		-moz-box-sizing: border-box !important;
		-webkit-box-sizing: border-box !important;
		box-sizing: border-box !important;
		text-align: center;
		padding-top: 10px;
		color: #7c7c7c;
		-webkit-box-shadow: 0px 3px 0px -2px rgba(255,255,255,1), 0px 2px 0px 0px rgba(169,169,169,1);
		-moz-box-shadow: 0px 3px 0px -2px rgba(255,255,255,1), 0px 2px 0px 0px rgba(169,169,169,1);
		box-shadow: 0px 3px 0px -2px rgba(255,255,255,1), 0px 2px 0px 0px rgba(169,169,169,1);
		cursor: pointer;
	}

	> span:hover {
		background-color: #75b4fb;
		border: 1px solid #5a88c5;
		color: #fff;
		text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
		-webkit-box-shadow: 0px 3px 0px -2px rgba(117,180,251,1), 0px 2px 0px 0px rgba(90,136,197,1);
		-moz-box-shadow: 0px 3px 0px -2px rgba(117,180,251,1), 0px 2px 0px 0px rgba(90,136,197,1);
		box-shadow: 0px 3px 0px -2px rgba(117,180,251,1), 0px 2px 0px 0px rgba(90,136,197,1);
		cursor: pointer;
	}

	> span:active {
		margin-top: 2px;
		box-shadow: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
	}

	&.icon > span
	{
		font-size: 14px;
	}
}

.hoverContainer {
	.hoverHide {
		display: flex;
	}
	.hoverShow {
		display: none;
	}
	&:hover {
		> .hoverHide {
			display: none;
		}
		> .hoverShow {
			display: flex;
		}
	}
}

.centerFlexElements {
	text-align: center;
	justify-content: center;
	align-items: center;
}


.row-highlight-error {
	background-color: lightcoral !important;
	color: white;
}

.row-highlight-total {
	background-color: black !important;
	color: white;
	font-weight: bold;
}

.row-highlight-total-error {
	background-color: red !important;
	color: white;
}

.row-highlight-false-positive {
	background-color: lightcoral !important;
}

.row-highlight-false-negative {
	background-color: lightgreen !important;
}

.MuiDataGrid-root .Mui-selected {
	background-color: #337ab7 !important;
}