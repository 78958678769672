.ynpCardAction__Grid {
  display: grid;
  grid-template-columns: repeat(3, 3fr) 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0;
  width: 100%;
}


.actionButton .showOnHover{
  display: none;
  margin-right: 1em;
}

.actionButton:hover .showOnHover {
  display: block;
}
.LogoCardAction-container {
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 4fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "input-logo input-logo Options"
    "input-placement input-placement Options";
  min-height: 75px;
  &.rejectedLogoActions {
    grid-template-rows: 1fr;
    grid-template-columns: 8fr 1fr;
  }
}
.input-logo-ctn {
  grid-area: input-logo;
  display: flex;
  .input-logo {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input-logo-reject {
    width: 10%;
  }
  .fillAllSpace {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: bolder;
  }
}


.input-placement {
  grid-area: input-placement;
}

.Options {
  grid-area: Options;
  display: flex;
  justify-content: center;
  align-items: center;
}

